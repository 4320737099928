export const useScreen = () => {
  const breakpoint = 700; // min 330x550

  const height = screen.availHeight;

  const isTall = computed(() => {
    return screen.availHeight > breakpoint;
  });

  return { height, isTall };
};
