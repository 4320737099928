<script setup lang="ts">
import ulubele from "../../assets/images/ulubele.svg";
import logo from "../../assets/images/logo.svg";
const { isTall } = useScreen();
const router = useRouter();
</script>

<template>
  <div
    class="flex flex-col justify-center grow mx-auto w-3/5 lg:mx-0 lg:grow-0 lg:w-auto"
    @click="router.push('/')"
  >
    <img :src="ulubele" alt="Ulubele" />
    <img v-if="isTall" :src="logo" alt="Ulubele" />
  </div>
</template>
